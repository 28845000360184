import React from "react"
import { css } from "@emotion/react"
import { color as csxColor } from "csx"
import moment from "moment"

import { color, width, fontFamily, mq } from "../../utils/style-components"
import triangleHeight from "../../components/top/triangle-height"

type NewsItemProps = {
  date: string
}
const han2zen = (str: string) => {
  return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, s =>
    String.fromCharCode(s.charCodeAt(0) - 0xfee0)
  )
}
const TimeStyle = css`
  font-size: 1.2rem;
  line-height: 32px;
  ${mq(
    `pc`,
    css`
      flex: 0 0 7em;
    `
  )}
  ${mq(
    `sp`,
    css`
      border-bottom: 1px solid ${color.white};
      display: block;
    `
  )}
`
export const NewsItem: React.FC<NewsItemProps> = ({ date, children }) => {
  const dateMoment = moment(han2zen(date))
  return (
    <li
      css={css`
        color: ${color.white};
        &:nth-of-type(n + 2) {
          margin-top: min(40px, 6.3vw);
        }
        ${mq(
          `pc`,
          css`
            display: flex;
            padding: 0 0.5em 0.5em;
            border-bottom: 1px solid ${color.white};
          `
        )}
      `}
    >
      {(dateMoment.isValid() && (
        <time css={TimeStyle} dateTime={dateMoment.format(`YYYY-MM-DD`)}>
          {dateMoment.format(`YYYY-MM-DD`)}
        </time>
      )) || <span css={TimeStyle}>---</span>}
      <div
        css={css`
          font-size: 1.6rem;
          line-height: 32px;
        `}
      >
        {children}
      </div>
    </li>
  )
}

const News: React.FC = ({ children }) => {
  return (
    <div
      css={css`
        background: ${csxColor(color.brown).fade(0.9).toString()};
      `}
    >
      <div
        css={css(
          css`
            position: relative;
            max-width: ${width.contentPC};
            width: ${width.contentSP};
            padding: min(110px, 9vw) ${width.paddingSP} min(200px, 18vw);
            margin: 0 auto;
            &::before {
              display: block;
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              height: 0;
              width: 0;
              transform: translateY(-100%);
              border-bottom: solid ${csxColor(color.brown).fade(0.9).toString()};
              border-right: min(104px, 10.28vw) solid transparent;
              border-left: min(104px, 10.28vw) solid transparent;
            }
          `,
            triangleHeight
        )}
      >
        <h2
          css={css`
            color: ${color.blue};
            font-family: ${fontFamily.decorate1};
            font-weight: 700;
            line-height: 1;
            ${mq(
              `pc`,
              css`
                font-size: 4.8rem;
              `
            )}
            ${mq(
              `sp`,
              css`
                font-size: 8.3vw;
              `
            )}
          `}
        >
          News
        </h2>
        <ul
          css={css`
            list-style: none;
            max-width: ${width.contentInner};
            margin: min(60px, 7.5vw) auto 0;
          `}
        >
          {children}
        </ul>
      </div>
    </div>
  )
}

export default News
