import { css } from "@emotion/react"
import { breakPoint } from "../../utils/style-components"

const triangleHeight = css`
    &::before {
        @media (min-width: ${breakPoint}px) {
            border-bottom-width: 75px;
        }
        @media (max-width: ${breakPoint - 1}px) {
            border-bottom-width: 66px;
        }
        @media (max-width: 740px) {
            border-bottom-width: 48px;
        }
        @media (max-width: 480px) {
            border-bottom-width: 31px;
        }
    }
`
export default triangleHeight
