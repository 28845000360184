import React from "react"
import { css } from "@emotion/react"
import { Link } from "gatsby"
import { color, mq, fontFamily } from "../../utils/style-components"

type MoreProps = {
  className?: string,
  to: string
}

const moreStyle = css`
  display: block;
  position: relative;
  width: 6em;
  color: ${color.blue};
  font-weight: 700;
  font-family: ${fontFamily.decorate1};
  line-height: 1;
  padding: 0 0 0.2em 0.1em;
  text-decoration: none;
  border-bottom: 2px solid ${color.blue};
  transition: width 0.5s cubic-bezier(0.17, 0.84, 0.44, 1);
  ${mq(
    `pc`,
    css`
      font-size: 2.1rem;
      &:hover {
        width: 7em;
      }
    `
  )}
  ${mq(
    `sp`,
    css`
      font-size: min(2rem, 4.37vw);
    `
  )}
  &::after {
    display: block;
    content: "";
    position: absolute;
    right: 0;
    bottom: -1px;
    height: 1.5em;
    width: 2px;
    background: ${color.blue};
    transform-origin: bottom center;
    transform: rotate(-70deg);
  }
`
const More: React.FC<MoreProps> = ({to, className}) => {
  return (
    <Link to={to} css={moreStyle} className={className}>
      MORE
    </Link>
  )
}

export default More
