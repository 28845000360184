import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/react"
import { color as csxColor } from "csx"

import { color, mq, fontFamily } from "../../utils/style-components"
import { ReactComponent as IconCircleArrowDown } from "../../img/common/icon_circle_arrow_down.svg"

type LinkItemProps = {
  title: string
  color: string
  to: string
}
const LinkItem: React.FC<LinkItemProps> = ({
  title,
  to,
  children,
  ...props
}) => {
  const bgColor = props.color
  const translucentWhite = csxColor(color.white).fade(0.2).toString();
  return (
    <div
      css={css`
        display: flex;
        position: relative;
        justify-content: center;
        background: ${bgColor};
        padding: min(180px,11vw) 0 min(77px, 11vw);
        ${mq(
          `pc`,
          css`
            flex: 0 0 50%;
            margin: 126px 0 200px;
          `
        )}
        ${mq(
          `sp`,
          css`
            &:last-of-type {
              margin-bottom: min(75px, 20vw);
            }
          `
        )}
        &::before {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          z-index: 0;
          ${mq(
            `pc`,
            css`
              width: min(860px, 40.87vw);
              height: min(311px, 15.41vw);
            `
          )}
          ${mq(
            `sp`,
            css`
              width: min(860px, 81.94vw);
              height: min(311px, 30.83vw);
            `
          )}
        }
        &:nth-of-type(odd)::before {
          right: 0;
          background: linear-gradient(-20deg, ${translucentWhite}, ${translucentWhite} 48%, transparent 48%);
        }
        &:nth-of-type(even)::before {
          left: 0;
          background: linear-gradient(20deg, ${translucentWhite}, ${translucentWhite} 48%, transparent 48%);
        }
      `}
    >
      <Link
        to={to}
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          color: ${color.white};
          text-decoration: none;
          z-index: 10;
        `}
      >
        <h2
          css={css`
            font-size: min(4.8rem, 8.3vw);
            font-weight: 700;
            line-height: 1;
            font-family: ${fontFamily.decorate1};
          `}
        >
          {title}
        </h2>
        <IconCircleArrowDown
          height="74"
          width="74"
          css={css`
            width: min(74px, 10vw);
            height: auto;
            margin: min(42px, 4.4vw) auto 0;
          `}
        />
        <p
          css={css`
            margin: min(42px, 4.4vw) auto 0;
            padding: 0 2vw;
            ${mq(
              `pc`,
              css`
              max-width: min(540px, 64.4vw);
              `
            )}
            ${mq(
              `sp`,
              css`
              max-width: min(540px, 80vw);
              `
            )}
          `}
        >
          {children}
        </p>
      </Link>
    </div>
  )
}

const ContactLinks: React.FC = () => {
  return (
    <div
      css={css`
        ${mq(
          `pc`,
          css`
            display: flex;
            margint: 120px 0 0;
          `
        )}
      `}
    >
      <LinkItem title="contact" to="/contact" color={color.brown}>
        プレス関係、行政関係、企業のSDGsご担当の方はこちらから
      </LinkItem>
      <LinkItem title="recruit" to="/recruit" color={color.green}>
        私達と共に八丈島でサスティナブルな未来社会の実現を目指しませんか？
        <br />
        移住・定住ご希望の方、新卒・第二新卒の方もちろん八丈島Uターン学生の方大歓迎です。
      </LinkItem>
    </div>
  )
}

export default ContactLinks
