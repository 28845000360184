import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { color as csxColor } from "csx"

import { color, width, fontFamily, mq } from "../../utils/style-components"
import triangleHeight from "../../components/top/triangle-height"
import More from "../common/more"

const ListItem = styled.li`
  position: relative;
  flex: 0 1 23%;
  margin-right: 2%;
  margin-bottom: 2%;
  @media (max-width: 900px) {
    flex: 0 1 31%;
    margin-right: 2%;
    margin-bottom: 2%;
  }
  @media (max-width: 680px) {
    flex: 0 1 48%;
    margin-right: 2%;
    margin-bottom: 2%;
  }
`
const Gallery: React.FC = () => {
  return (
    <div
      css={css`
        background: ${color.white};
      `}
    >
      <div
        css={css(
          css`
            position: relative;
            max-width: ${width.contentPC};
            width: ${width.contentSP};
            padding: min(110px, 9vw) ${width.paddingSP} min(200px, 18vw);
            margin: 0 auto;
            &::before {
              display: block;
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              height: 0;
              width: 0;
              transform: translateY(-100%);
              border-bottom: solid ${color.white};
              border-right: min(104px, 10.28vw) solid transparent;
              border-left: min(104px, 10.28vw) solid transparent;
            }
          `,
          triangleHeight
        )}
      >
        <h2
          css={css`
            color: ${color.blue};
            font-family: ${fontFamily.decorate1};
            font-weight: 700;
            line-height: 1;
            ${mq(
              `pc`,
              css`
                font-size: 4.8rem;
              `
            )}
            ${mq(
              `sp`,
              css`
                font-size: 8.3vw;
              `
            )}
          `}
        >
          GALLERY
        </h2>
        <div
          css={css`
            position: relative;
            max-width: ${width.contentInner};
            margin: min(60px, 7.5vw) auto 0;
            &::after {
              content: "";
              display: block;
              position: absolute;
              right: 0;
              bottom: 0;
              left: 0;
              height: 50%;
              background: linear-gradient(
                0deg,
                ${color.white},
                ${color.white} 20%,
                ${csxColor(color.white).fade(0).toString()}
              );
            }
          `}
        >
          <ul
            css={css`
              list-style: none;
              margin-right: -2%;
              display: flex;
              flex-wrap: wrap;
            `}
          >
            <ListItem>
              <StaticImage
                src="../../img/gallery/gallery_images/location_01.jpg"
                alt=""
                width={253}
                aspectRatio={3 / 2}
              />
            </ListItem>
            <ListItem>
              <StaticImage
                src="../../img/gallery/gallery_images/location_03.jpg"
                alt=""
                width={253}
                aspectRatio={3 / 2}
              />
            </ListItem>
            <ListItem>
              <StaticImage
                src="../../img/gallery/gallery_images/office_04.jpg"
                alt=""
                width={253}
                aspectRatio={3 / 2}
              />
            </ListItem>
            <ListItem>
              <StaticImage
                src="../../img/gallery/gallery_images/office_05.jpg"
                alt=""
                width={253}
                aspectRatio={3 / 2}
              />
            </ListItem>
            <ListItem>
              <StaticImage
                src="../../img/gallery/gallery_images/office_01.jpg"
                alt=""
                width={253}
                aspectRatio={3 / 2}
              />
            </ListItem>
            <ListItem>
              <StaticImage
                src="../../img/gallery/gallery_images/office_03.jpg"
                alt=""
                width={253}
                aspectRatio={3 / 2}
              />
            </ListItem>
            <ListItem>
              <StaticImage
                src="../../img/gallery/gallery_images/location_04.jpg"
                alt=""
                width={253}
                aspectRatio={3 / 2}
              />
            </ListItem>
            <ListItem>
              <StaticImage
                src="../../img/gallery/gallery_images/location_06.jpg"
                alt=""
                width={253}
                aspectRatio={3 / 2}
              />
            </ListItem>
          </ul>
        </div>
        <More
          to="/gallery"
          css={css`
            margin: min(60px, 7vw) auto 0;
          `}
        />
      </div>
    </div>
  )
}

export default Gallery
