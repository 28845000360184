import React, { SetStateAction, useEffect, useRef } from "react"
import { css } from "@emotion/react"
import { color } from "../../utils/style-components"
import BigNumber from "bignumber.js"
import logoUrl from "../../img/common/logo.svg"
import { loadingStateType } from "../../pages/index"

const timing = {
  wrapperDuration: 1,
  wrapperDelay: 3.5,
}

type LoadingType = {
  loadingRate: number
  loadingState: loadingStateType
  setLoadingState: React.Dispatch<SetStateAction<loadingStateType>>
}

const Loading: React.FC<LoadingType> = ({
  loadingRate,
  loadingState,
  setLoadingState,
}) => {
  const rate = new BigNumber(loadingRate)
    .times(100)
    .dp(2, BigNumber.ROUND_DOWN)
    .toNumber()

  const logoRef = useRef<HTMLDivElement>(null)
  if (logoRef.current) {
    logoRef.current.style.backgroundPositionY = `${rate}%`
  }

  useEffect(() => {
    if (loadingRate === 1) {
      setTimeout(() => {
        setLoadingState("fading")
      }, timing.wrapperDelay * 1000)
      setTimeout(() => {
        setLoadingState("done")
      }, (timing.wrapperDuration + timing.wrapperDelay) * 1000)
    }
  }, [loadingRate])

  if (loadingState === `done`) {
    return null
  }

  return (
    <div
      className="wrapper"
      css={css`
        display: flex;
        opacity: ${loadingRate === 1 ? 0 : 1};
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1000;
        overflow: hidden;
        transition: opacity ${timing.wrapperDuration}s ${timing.wrapperDelay}s;
        background: ${color.white};
        }
      `}
    >
      <div>
        <Subtitle />
        <div
          ref={logoRef}
          css={css`
            mask-image: url("${logoUrl}");
            mask-type: alpha;
            mask-position: center;
            mask-size: contain;
            mask-repeat: no-repeat;
            background: no-repeat
              linear-gradient(180deg, #ddd 50%, ${color.font} 50%);
            background-position: center 0%;
            background-size: 100% 200%;
            height: 87px;
            margin-right: 25px;
            width: 210px;
            transition: background-position-y 0.8s 0.3s;
          `}
        />
        <h1
          css={css`
            margin-top: 20px;
            font-size: 1.6rem;
            line-height: 2.5;
            text-align: center;
            font-weight: 400;
            letter-spacing: 0.1em;
            opacity: ${loadingRate === 1 ? 1 : 0};
            transition: opacity 1s 1.5s;
          `}
        >
          -てんねい-
          <br />
          八丈島の島言葉で
          <br />
          「天空」
        </h1>
      </div>
    </div>
  )
}

const Subtitle: React.FC = () => {
  const subtitleRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (subtitleRef.current) {
      let subtitleText = ""
      Array.from(subtitleRef.current.innerText).forEach(text => {
        subtitleText += `<span>${text}</span>`
      })
      subtitleRef.current.innerHTML = subtitleText
      setTimeout(() => {
        subtitleRef.current?.classList.add(`active`)
      }, 500)
    }
  }, [subtitleRef])

  let spanTransitions = ``
  for (let i = 0; i < 50; i++) {
    spanTransitions += `
      &:nth-of-type(${i}) {
        transition: opacity 0.5s ${0.04 * i}s;
      }
    `
  }

  return (
    <h2
      ref={subtitleRef}
      css={css`
        margin-bottom: 20px;
        font-size: 1.4rem;
        line-height: 1;
        text-align: center;
        font-weight: 400;
        letter-spacing: 0.1em;
        opacity: 0;
        & > span {
          opacity: 0;
          ${spanTransitions}
        }
        &.active,
        &.active > span {
          opacity: 1;
        }
      `}
    >
      SDGs共創と実証実験の場
    </h2>
  )
}

export default Loading
