import React, { useState } from "react"
import { PageProps } from "gatsby"
import { css } from "@emotion/react"
import Layout from "../components/layout"

import BackgroundMovie from "../components/top/background_movie"
import Loading from "../components/top/loading"
import SEO from "../components/seo"
import Hero from "../components/top/hero"
import Introduction from "../components/top/introduction"
import News, { NewsItem } from "../components/top/news"
import Gallery from "../components/top/gallery"
import ContactLinks from "../components/top/contact-links"
import Access from "../components/top/access"

export type loadingStateType = `loading` | `fading` | `done`

type HomeLocation = {
  skipMovie?: boolean
}
const Home: React.FC<PageProps<null, null, HomeLocation>> = ({location}) => {
  const startState = location?.state?.skipMovie ? `done` : `loading`
  const [loadingRate, setLoadingRate] = useState(0)
  const [loadingState, setLoadingState] = useState<loadingStateType>(startState)

  return (
    <Layout>
      <SEO />
      <Loading
        loadingRate={loadingRate}
        loadingState={loadingState}
        setLoadingState={setLoadingState}
      />
      <BackgroundMovie
        loadingRate={loadingRate}
        loadingState={loadingState}
        setLoadingRate={setLoadingRate}
      />
      {loadingState !== `loading` && (
        <div
          css={css`{
            position: relative;
            z-index: 1;
          `}
        >
          <Hero />
          <Introduction />
          <News>
            <NewsItem date="2024/02/20">
              <a href="https://group-fm.com/news/detail/20240220/" target="_blank" rel="noopener">100%再生可能エネルギーで映像編集やXR開発を行う「8jo Zero Emission Factory」実現に向けた風力発電の試運転を開始</a>
            </NewsItem>
            <NewsItem date="2022/07/26">
              <a href="https://group-fm.com/news/detail/20220726/" target="_blank" rel="noopener">「8jo Zero Emission Factory構想」を発表</a>
            </NewsItem>
            <NewsItem date="2021/10/12">
              移住定住を目的とした合宿形式のデジタルスクール「<a href="https://8jo.digital" target="_blank" rel="noopener">8jo Digital Camp</a>」を開講します。
            </NewsItem>
            <NewsItem date="2021/06/01">
              TENNEI公式サイトをオープンしました。
            </NewsItem>
          </News>
          <Gallery />
          <ContactLinks />
          <Access />
        </div>
      )}
    </Layout>
  )
}

export default Home
