import React, { SetStateAction, useEffect, useRef } from "react"
import BigNumber from "bignumber.js"
import { css } from "@emotion/react"
import { header, mq } from "../../utils/style-components"
import { loadingStateType } from "../../pages/index"

//import Yagura from "../../video/yagura.mp4"
import Movie from "../../video/8jo0509_A.mp4"

type BackgroundMovieType = {
  loadingRate: number
  loadingState: loadingStateType
  setLoadingRate: React.Dispatch<SetStateAction<number>>
}
const BackgroundMovie: React.FC<BackgroundMovieType> = ({
  loadingRate,
  loadingState,
  setLoadingRate,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  let canPlayThrough = false

  let timeout = false
  let timeoutId = setTimeout(() => {
    timeout = true
  }, 5000)

  useEffect(() => {
    const video = videoRef.current
    if (video) {
      video.addEventListener(`canplaythrough`, () => {
        canPlayThrough = true
      })

      requestAnimationFrame(function watchLoadingRate() {
        if (
          video.duration &&
          video.buffered.length === 1 &&
          !timeout &&
          !canPlayThrough
        ) {
          let bufferEnd = new BigNumber(video.buffered.end(0))
          let bufferRate = bufferEnd.div(video.duration).toNumber()
          setLoadingRate(bufferRate)
        }
        if (loadingRate > 0.99 || timeout || canPlayThrough) {
          setLoadingRate(1)
        } else {
          requestAnimationFrame(watchLoadingRate)
        }
      })
    }
  }, [])

  if (videoRef.current && loadingState === `fading`) {
    videoRef.current.volume = 0
    videoRef.current.play()
    clearTimeout(timeoutId)
  }

  return (
    <div
      css={css`
        position: fixed;
        ${mq(
          `pc`,
          css`
            top: ${header.height};
          `
        )}
        ${mq(
          `sp`,
          css`
            top: 0;
          `
        )}
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1000;
        overflow: hidden;
      `}
    >
      <video
        ref={videoRef}
        loop
        muted
        preload="auto"
        playsInline
        //autoPlay
        css={css`
          min-width: 100%;
          min-height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        `}
      >
        <source src={Movie} type="video/mp4" />
      </video>
    </div>
  )
}

export default BackgroundMovie
