import React, { useState } from "react"
import { css } from "@emotion/react"
import { color, mq } from "../../utils/style-components"

const Hero: React.FC = () => {
  return (
    <div
      css={css`
        position: relative;
        ${mq(
          `pc`,
          css`
            height: 85vh;
          `
        )}
        ${mq(
          `sp`,
          css`
            height: 75vh;
          `
        )}
      `}
    >
      <h2
        css={css`
          position: absolute;
          left: 0;
          bottom: 0;
          right: 0;
          font-weight: 700;
          color: ${color.white};
          text-align: center;
          ${mq(
            `pc`,
            css`
              font-size: 3.8rem;
              line-height: 1.8;
            `
          )}
          ${mq(
            `sp`,
            css`
              font-size: 1.6rem;
              line-height: 2.25;
            `
          )}
        `}
      >
        クリエイティブの力で世界の課題に取り組み、
        <br />
        持続可能でよりよい世界を実現する。
        <br />
        ここ、八丈島から。
      </h2>
    </div>
  )
}

export default Hero
