import React from "react"
import { css } from "@emotion/react"
import { color as csxColor } from "csx"

import { color, width, fontFamily, mq } from "../../utils/style-components"
import triangleHeight from "../../components/top/triangle-height"

const Access: React.FC = () => {
  return (
    <div
      css={css`
        background: ${csxColor(color.blue).fade(0.9).toString()};
      `}
    >
      <div
        css={css(
          css`
            position: relative;
            max-width: ${width.contentPC};
            width: ${width.contentSP};
            padding: min(110px, 9vw) ${width.paddingSP} min(200px, 20vw);
            margin: 0 auto;
            &::before {
              display: block;
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              margin: 0 auto;
              height: 0;
              width: 0;
              transform: translateY(-100%);
              border-bottom: solid ${csxColor(color.blue).fade(0.9).toString()};
              border-right: min(104px, 10.28vw) solid transparent;
              border-left: min(104px, 10.28vw) solid transparent;
            }
          `,
          triangleHeight
        )}
      >
        <h2
          id="access"
          css={css`
            color: ${color.white};
            font-family: ${fontFamily.decorate1};
            font-weight: 700;
            line-height: 1;
            text-align: center;
            ${mq(
              `pc`,
              css`
                font-size: 4.8rem;
              `
            )}
            ${mq(
              `sp`,
              css`
                font-size: 8.3vw;
              `
            )}
          `}
        >
          ACCESS
        </h2>
        <div
          css={css`
            max-width: ${width.contentInner};
            margin: min(60px, 7.5vw) auto 0;
          `}
        >
          <div
            css={css`
              position: relative;
              z-index: 10;
              max-width: 730px;
              width: 88.89vw;
              margin: 0 auto;
              padding: min(20px, 4vw) 0;
              background: ${color.white};
              box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            `}
          >
            <h3
              css={css`
                max-width: 329px;
                width: 80vw;
                background: ${color.blue};
                margin: 0 auto;
                text-align: center;
                font-weight: 700;
                letter-spacing: 0.1em;
                line-height: 2;
                color: ${color.white};
                ${mq(
                  `pc`,
                  css`
                    font-size: 2rem;
                  `
                )}
                ${mq(
                  `sp`,
                  css`
                    font-size: 1.4rem;
                  `
                )}
              `}
            >
              TENNEI -てんねい-
            </h3>
            <p
              css={css`
                padding-top: 5px;
                text-align: center;
                color: ${color.blue};
                ${mq(
                  `sp`,
                  css`
                    font-size: 1.3rem;
                  `
                )}
              `}
            >
              〒100-1511　東京都八丈島八丈町三根1988-2
            </p>
          </div>
          <iframe
            title="Tenneiへのアクセスマップ"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1670.9687353980412!2d139.7944903582652!3d33.11072769521811!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6015710b39ca30af%3A0x889bebff24446a3!2sTENNEI!5e0!3m2!1sja!2sjp!4v1618902882636!5m2!1sja!2sjp"
            width="600"
            height="450"
            loading="lazy"
            css={css`
              border: none;
              width: 100%;
              ${mq(
                `pc`,
                css`
                  margin-top: -15px;
                  z-index: 0;
                `
              )}
              ${mq(
                `sp`,
                css`
                  margin-top: 5vw;
                `
              )}
            `}
          ></iframe>
        </div>
      </div>
    </div>
  )
}

export default Access
